<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-4">
        <button v-if="role == 'Registrar' || (role == 'DC' && complaint.status != 'Close')"
          class="btn btn-primary btn-sm  float-end" @click="updateModal = true">
          Update Status
        </button>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button"
              role="tab" aria-controls="home" aria-selected="true">
              Complaint Detail
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button"
              role="tab" aria-controls="profile" aria-selected="false">
              Complaint Tracking
            </button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active p-4 bg-white mb-5" id="home" role="tabpanel" aria-labelledby="home-tab">
            <div class="row">
              <div class="col-md-8">
                <p>
                  {{ complaint.complaint_detail }}
                </p>
              </div>
              <div class="col-md-2">
                <div class="row">
                  <div class="col">
                    <strong style="color: #9394a4">Reference No.</strong>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col">
                    <template v-if="complaint.referenceNo">
                      <p>{{ complaint.referenceNo }}</p>
                    </template>
                    <template v-else-if="!complaint.referenceNo && role == 'DC'">
                      <a href="#" @click="addRef" style="color: #0468B1 !important;">Add REF</a>
                    </template>
                    <template v-else>
                      <p>N/A</p>
                    </template>

                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="row">
                  <div class="col">
                    <strong style="color: #9394a4">Status</strong>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col">
                    <span v-if="complaint.isCmcComplaint != 1" class="badge badge-pill badge-light"
                      style="color: #009f19; font-size: 1.0em;">{{ complaint.status == 'Close' ? 'Closed' : complaint.status == 'Cancel' ? 'Cancelled' : complaint.status == 'Complete' ? 'Completed' : complaint.status == 'Submit' ? 'Submitted' : complaint.status }}
                    </span>


                  </div>
                </div>
              </div>
            </div>

            <!-- Second Row -->
            <div class="row mt-3">
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col">
                        <strong style="color: #9394a4">Complainant Name</strong>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col">
                        <p>{{ complaint.user_profile.name }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col">
                        <strong style="color: #9394a4">Title</strong>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col">
                        <p>{{ complaint.title }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col">
                        <strong style="color: #9394a4">Date</strong>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col">
                        <p>{{ complaint.complaint_date }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col">
                        <strong style="color: #9394a4">Department</strong>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col">
                        <p>{{ complaint.department }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="row">
                      <div class="col">
                        <strong style="color: #9394a4">Location</strong>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col">
                        <p>{{ complaint.location }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="row">
                      <div class="col">
                        <strong style="color: #9394a4">Download Document</strong>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col" v-if="documentUrl">
                        <button type="button" class="btn btn-sm btn-danger rounded" @click="openPdf">
                          <i class="fa fa-download"></i> Download
                        </button>
                      </div>
                      <div class="col" v-else>
                        <p>No document</p>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
              <div class="col-md-4">
                <div class="row">
                  <div class="col">
                    <button
                      v-if="((role == 'DC' && !complaint.referenceNo) || role == 'Department-Head' || role == 'inquiry-officer' || role == 'Department-FocalPerson') && (complaint.status == 'Not Assigned' || (complaint.status == 'Submit' && role == 'Department-Head')) && complaint.status != 'Submit'"
                      @click="revertModal = true" type="button" class="btn btn-sm btn-danger rounded">
                      Revert
                    </button>
                  </div>
                </div>

                <div class="row mt-2"
                  v-if="complaint.isCmcComplaint != 1 && ['Registrar', 'Department-Unit', 'Department-FocalPerson', 'Department-Head', 'inquiry-officer', 'DC'].includes(role) && complaint.status != 'Appealed' && complaint.status != 'Withdrawn' && complaint.status != 'Cancel' && complaint.status != 'Close'">
                  <div class="col" v-if="role != 'Department-Unit'">
                    <button v-if="role != 'Department-Head'"
                      :disabled="complaint.status === 'Submit' || complaint.status === 'Assigned' || complaint.status === 'Complete' || complaint.status === 'Close' || complaint.status === 'In-progress'"
                      type="button" @click="showModal = true" class="btn btn-sm btn-success rounded">
                      <span v-if="complaint.status === 'Assigned'">Assigned</span>
                      <span v-else-if="role == 'DC' && complaint.status === 'Complete'">Status: Complete - Action needed
                        to
                        close</span>
                      <span v-else>Assign</span>
                    </button>
                    <button v-if="role == 'Department-Head'"
                      :disabled="complaint.status === 'Assigned' || complaint.status == 'Complete' || complaint.status == 'Close' || complaint.status === 'In-progress'"
                      type="button" @click="showModal = true" class="btn btn-sm btn-success rounded">
                      <span v-if="complaint.status === 'Assigned'">Assigned</span>
                      <span v-else>Assign</span>
                    </button>
                  </div>
                  <div class="col" v-else>
                    <button :disabled="complaint.status == 'Submit'" type="button" @click="showModal = true"
                      class="btn btn-sm btn-success rounded">
                      <span v-if="complaint.status == 'Submit'">Submit</span>
                      <span v-else>Submit</span>
                    </button>
                  </div>

                </div>

                <div v-if="complaint.isCmcComplaint != 1 && (role == 'DC' || role=='Registrar'  ) && complaint.status == 'Appealed'">
                  <div class="row mt-2">
                    <div class="col">
                      <button @click="reOpenAppeal = true" type="button" class="btn btn-sm btn-success rounded">
                        <span>Re Open</span>
                      </button>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col">
                      <button type="button" @click="cancelAppeal = true" class="btn btn-sm btn-danger rounded"
                        style="background-color: #dc3545 !important; border-color: #dc3545 !important;">
                        <span>Cancel</span>
                      </button>
                    </div>

                  </div>

                </div>


                <div class="row mt-2">
                  <div class="col" v-if="role == 'Department-Head' && complaint.status == 'Submit'">
                    <button :disabled="complaint.status == 'Complete'" type="button"
                      class="btn btn-sm btn-success rounded" @click="completeStatus = true">
                      <span>Complete</span>
                    </button>
                  </div>


                </div>



              </div>
            </div>
            <!-- Second Row -->




            <div class="row mt-3" v-if="complaint.isCmcComplaint != 1">
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col">
                        <strong style="color: #9394a4">Complainant Stage</strong>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col">
                        <p v-if="complaint.status == 'close'">Resolution</p>
                        <p v-else>{{ complaint.stage }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="col-md-12 mt-5" v-if="complaint.sop">
              <div class="row">
                <div class="w-100" role="alert"
                  style="background-color: #0468B1; color: white; padding: 15px; border-radius: 4px;">
                  <strong>SOP Instructions:</strong> {{ complaint.sop }}
                </div>
              </div>
            </div>



            <!-- Third Row -->
            <div class="col-md-12 mt-5">
              <div class="row">
                <img src="/map.png" alt="Image Description" class="img-fluid" />
              </div>
            </div>


          </div>
          <div class="tab-pane fade p-4 bg-white mb-5" id="profile" role="tabpanel" aria-labelledby="profile-tab">
            <div class="comment-widgets m-b-20">

              <button type="button" @click="showCommentModel = true" class="btn btn-primary btn-block">
                Add Comment
              </button>

              <div v-if="tracking?.length === 0" class="text-center">
                No action performed on this complaint.
              </div>

              <div v-else>
                <div v-for="(track, index) in tracking" :key="index">
                  <div class="d-flex flex-row comment-row">
                    <div class="p-2 pt-0">
                      <span class="round"><img src="https://i.imgur.com/uIgDDDd.jpg" alt="user" width="50" /></span>
                    </div>
                    <div class="comment-text w-100">
                      <strong>{{ track.name }}</strong>
                      <div class="comment-footer">
                        <span class="date" style="color: #9394a4">{{ track.role }}</span>
                      </div>

                      <p class="m-b-5 m-t-10">
                        {{ track.comment }}
                      </p>

                      <!-- <div v-if="track?.media?.length !== 0">
                        <br>
                        <p class="m-b-5 m-t-10">
                          Attached Files <a href="#" @click="openFiles($event, track)">Files</a>
                        </p>
                      </div> -->



                      <div class="col-md-12" :class="{ 'mt-3': track.comment }">
                        <div class="col-md-6">
                          <div class="card">
                            <div class="card-body p-3">
                              <div class="row">
                                <div class="col-md-12">
                                  {{ track.activity }}
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12 text-center mt-1">
                                  {{ track.action_at }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div class="row" v-if="track.files.length > 0">
                        <div class="col-md-12 mt-1">
                          <button type="button" class="btn btn-primary btn-block"
                            @click="openTrackingFile(track.files)">
                            Attachments
                          </button>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div v-if="showModal" class="modal-mask" @click="closeModal">
          <div class="modal-wrapper" @click.stop>
            <div class="modal-container scrollable">
              <div class="modal-header mt-3" style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                ">
                <h3 v-if="role !== 'Department-Unit'">Assign</h3>
                <h3 v-else>Submit</h3>

              </div>
              <div class="modal-body">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-8 offset-md-2">
                      <form @submit.prevent="assignAdmin">


                        <template
                          v-if="['DC', 'inquiry-officer', 'Department-Head', 'Department-Unit', 'Department-FocalPerson', 'Registrar'].indexOf(role) === -1">
                          <div>
                            <label class="col-form-label" style="
                            font-family: sans-serif;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 24px;
                          ">Country</label>
                            <div class="select-container">
                              <select class="form-control form-control-sm border-0 select-field"
                                @change="countryChanged" v-model="filterData.countryId" required>
                                <option value="" disabled selected hidden>
                                  Select..
                                </option>
                                <option v-for="country in countries" :value="country.id">
                                  {{ country.name }}
                                </option>

                              </select>
                              <i class="fa fa-caret-down select-icon"></i>
                            </div>
                            <label class="col-form-label" style="
                            font-family: sans-serif;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 24px;
                          ">Province</label>
                            <div class="select-container">
                              <select class="form-control form-control-sm border-0 select-field"
                                v-model="filterData.provinceId" @change="provinceChanged" required>
                                <option value="" disabled selected hidden>
                                  Select..
                                </option>
                                <option v-for="province in provinces" :value="province.id">
                                  {{ province.name }}
                                </option>

                              </select>
                              <i class="fa fa-caret-down select-icon"></i>
                            </div>


                            <label class="col-form-label" style="
                            font-family: sans-serif;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 24px;
                          ">District</label>
                            <div class="select-container">
                              <select class="form-control form-control-sm border-0 select-field"
                                v-model="filterData.districtId" @change="getDc" required>
                                <option value="" disabled selected hidden>
                                  Select..
                                </option>Fs
                                <option v-for="district in districts" :value="district.id">
                                  {{ district.name }}
                                </option>

                              </select>
                              <i class="fa fa-caret-down select-icon"></i>
                            </div>




                          </div>
                        </template>



                        <div v-if="role == 'Registrar'">


                          <div class="col-md-8">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" name="exampleRadios" id="exampleRadios1"
                                value="otherRegistrar" @change="otherRegistrar($event)">
                              <label class="form-check-label" for="exampleRadios1">
                                Assign Other District Registrar
                              </label>
                            </div>
                          </div>


                          <label class="col-form-label" style="
                            font-family: sans-serif;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 24px;
                          ">District</label>
                          <div class="select-container">
                            <select v-model="filterData.districtId" @change="getRegistrar"
                              class="form-control form-control-sm border-0 select-field"
                              :disabled="!filterData.otherRegistrar" required>
                              <option value="" disabled selected hidden>
                                Select..
                              </option>
                              <option v-for="district in districts" :value="district.id">
                                {{ district.name }}
                              </option>

                            </select>
                            <i class="fa fa-caret-down select-icon"></i>
                          </div>


                        </div>


                        <div class="row" v-if="role == 'DC'">

                          <label for="">Designation</label>
                          <div class="col-md-3">
                            <div class="form-check">
                              <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1"
                                value="IO" checked @change="radioChanged">
                              <label class="form-check-label" for="exampleRadios1">
                                IO
                              </label>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-check">
                              <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2"
                                value="DH" @change="radioChanged">
                              <label class="form-check-label" for="exampleRadios2">
                                DH
                              </label>
                            </div>
                          </div>

                          <div v-if="dcassignrole.role == 'DH'">

                            <label class="col-form-label" style="
                            font-family: sans-serif;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 24px;
                          ">Select Department</label>
                            <div class="select-container">
                              <select class="form-control form-control-sm border-0 select-field"
                                @change="getDepartmentHead" v-model="dcassignrole.departmentId" required>
                                <option value="" disabled selected hidden>
                                  Select..
                                </option>
                                <option v-for="department in dcassignrole.departments" :value="department.id">
                                  {{
                                    department.name }}
                                </option>

                              </select>
                              <i class="fa fa-caret-down select-icon"></i>
                            </div>

                          </div>



                        </div>


                        <div class="row" v-if="role == 'inquiry-officer'">


                          <label class="col-form-label" style="
                            font-family: sans-serif;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 24px;
                          ">Select Department</label>
                          <div class="select-container">
                            <select class="form-control form-control-sm border-0 select-field"
                              @change="getDepartmentHead" v-model="dcassignrole.departmentId" required>
                              <option value="" disabled selected hidden>
                                Select..
                              </option>
                              <option v-for="department in dcassignrole.departments" :value="department.id">
                                {{
                                  department.name }}
                              </option>

                            </select>
                            <i class="fa fa-caret-down select-icon"></i>
                          </div>


                        </div>


                        <div class="row" v-if="role == 'Department-Head'">
                          <label for="">Designation</label>
                          <div class="col-md-6">
                            <div class="form-check">
                              <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1"
                                value="unit" checked @change="dhSelectDesignation" v-model="dhassignrole.role">
                              <label class="form-check-label" for="exampleRadios1">
                                Department Unit
                              </label>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-check">
                              <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2"
                                value="focal" @change="dhSelectDesignation" v-model="dhassignrole.role">
                              <label class="form-check-label" for="exampleRadios2">
                                Focal Person
                              </label>
                            </div>
                          </div>

                          <div v-if="dhassignrole.role == 'unit' || role == 'Department-FocalPerson'">
                            <label class="col-form-label" style="
                            font-family: sans-serif;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 24px;
                          ">Select Department Unit </label>
                            <div class="select-container">
                              <select class="form-control form-control-sm border-0 select-field"
                                id="departmentUnitSelect" @change="getDepartmentUnit($event)" required>
                                <option value="" disabled selected hidden>
                                  Select..
                                </option>
                                <option v-for="admin in admins" :value="admin.id" :key="admin.admin_id">
                                  {{
                                    admin.unit_name }}
                                </option>

                              </select>
                              <i class="fa fa-caret-down select-icon"></i>
                            </div>
                          </div>
                        </div>
                        <div v-if="role == 'Department-FocalPerson'">
                          <label class="col-form-label" style="
                            font-family: sans-serif;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 24px;
                          ">Select Department Unit </label>
                          <div class="select-container">
                            <select class="form-control form-control-sm border-0 select-field" id="departmentUnitSelect"
                              @change="getDepartmentUnit($event)" required>
                              <option value="" disabled selected hidden>
                                Select..
                              </option>
                              <option v-for="admin in admins" :value="admin.id" :key="admin.admin_id">
                                {{
                                  admin.unit_name }}
                              </option>

                            </select>
                            <i class="fa fa-caret-down select-icon"></i>
                          </div>
                        </div>

                        <div v-if="role != 'Department-Unit' || role != 'Department-Unit'">
                          <label class="col-form-label" style="
                            font-family: sans-serif;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 24px;
                          ">Assign To</label>
                          <div class="select-container">
                            <select class="form-control form-control-sm border-0 select-field" v-model="assign.admin_id"
                              required>
                              <option value="" disabled selected hidden>
                                Select..
                              </option>
                              <option v-for="admin in admins" :value="admin.admin.id">
                                {{
                                  admin.admin.email }}
                              </option>

                            </select>
                            <i class="fa fa-caret-down select-icon"></i>
                          </div>
                        </div>

                        <label class="col-form-label" style="
                            font-family: sans-serif;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 24px;
                          ">Feedback</label>

                        <div class="d-flex align-items-center mb-3" style="
                            border-bottom: 2px solid #ff432a;
                            padding-bottom: 5px;
                          ">
                          <textarea class="form-control form-control-sm border-0" placeholder="Type Here"
                            v-model="assign.comment" style="
                              border: none;
                              outline: none;
                              box-shadow: none;
                              height: 1.5em;
                            "></textarea>
                        </div>

                        <div>
                          <div>
                            <label class="mt-2">Attachments</label>
                          </div>
                          <input ref="fileInputComments" @change="uploadAttachment" type="file" accept="image/*,video/*"
                            multiple class="form-control" />
                        </div>

                        <div v-if="filesBase64.length > 0" class="mt-3">
                          <h5>File Previews:</h5>
                          <div v-for="(file, index) in filesBase64" :key="index" class="mb-2">
                            <a href="#" style="color: #0468B1;" @click.prevent="openFilesComment(file)">{{ file.name
                              }}</a>
                          </div>
                        </div>

                        <div v-if="role == 'DC'">
                          <label class="col-form-label" style="
                            font-family: sans-serif;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 24px;
                          ">SOP Instructions</label>

                          <div class="d-flex align-items-center mb-3" style="
                            border-bottom: 2px solid #ff432a;
                            padding-bottom: 5px;
                          ">
                            <textarea class="form-control form-control-sm border-0" placeholder="Type Here"
                              v-model="assign.remark" style="
                              border: none;
                              outline: none;
                              box-shadow: none;
                              height: 3em;
                            "></textarea>
                          </div>
                        </div>


                        <div v-if="role == 'DC'">
                          <div class="col-md-8">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" name="exampleRadios" id="exampleRadios1"
                                v-model="assign.isPriority" style="border: 1px solid black;">
                              <label class="form-check-label" for="exampleRadios1">
                                On Priority
                              </label>
                            </div>
                          </div>
                        </div>





                        <div class="row mt-5">
                          <div class="col-md-8">
                            <button type="submit" class="btn btn-lg btn-block w-100 rounded"
                              style="background-color: #0468B1; color: white">
                              <span v-if="role !== 'Department-Unit'">Assign</span>
                              <span v-else>Submit</span>

                            </button>
                          </div>
                          <div class="col-md-3 mt-3 mt-md-0">
                            <button type="button" @click="closeModal"
                              class="btn btn-outline-primary btn-lg btn-block rounded">
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="completeStatus" class="modal-mask" @click="closeCompleteModal">
          <div class="modal-wrapper" @click.stop>
            <div class="modal-container scrollable">
              <div class="modal-header mt-3" style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                ">
                Status Update
              </div>
              <div class="modal-body">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-8 offset-md-2">
                      <form>


                        <label class="col-form-label" style="
                            font-family: sans-serif;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 24px;
                          ">Feedback</label>

                        <div class="d-flex align-items-center mb-3" style="
                            border-bottom: 2px solid #ff432a;
                            padding-bottom: 5px;
                          ">
                          <input class="form-control form-control-sm border-0" placeholder="Type Here"
                            v-model="assign.comment" style="
                              border: none;
                              outline: none;
                              box-shadow: none;
                            " />
                        </div>

                        <div>
                          <div>
                            <label class="mt-2">Attachments</label>
                          </div>
                          <input ref="fileInputComments" @change="uploadAttachment" type="file" accept="image/*,video/*"
                            multiple class="form-control" />
                        </div>

                        <div v-if="filesBase64.length > 0" class="mt-3">
                          <h5>File Previews:</h5>
                          <div v-for="(file, index) in filesBase64" :key="index" class="mb-2">
                            <a href="#" style="color: #0468B1;" @click.prevent="openFilesComment(file)">{{ file.name
                              }}</a>
                          </div>
                        </div>

                        <div class="row mt-5">
                          <div class="col-md-8">
                            <button @click="CompleteStatus" type="button" class="btn btn-lg btn-block w-100"
                              style="background-color: #0468B1; color: white">
                              Complete
                            </button>
                          </div>
                          <div class="col-md-3 mt-3 mt-md-0">
                            <button type="button" @click="closeCompleteModal"
                              class="btn btn-outline-primary btn-lg btn-block rounded">
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="cancelAppeal" class="modal-mask" @click="cancelAppealModal">
          <div class="modal-wrapper" @click.stop>
            <div class="modal-container scrollable">
              <div class="modal-header mt-3" style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                ">
                Cancel Appeal
              </div>
              <div class="modal-body">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-8 offset-md-2">
                      <form>


                        <label class="col-form-label" style="
                            font-family: sans-serif;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 24px;
                          ">Reason</label>

                        <div class="d-flex align-items-center mb-3" style="
                            border-bottom: 2px solid #ff432a;
                            padding-bottom: 5px;
                          ">
                          <input class="form-control form-control-sm border-0" placeholder="Type Here"
                            v-model="assign.comment" style="
                              border: none;
                              outline: none;
                              box-shadow: none;
                            " />
                        </div>

                        <div>
                          <div>
                            <label class="mt-2">Attachments</label>
                          </div>
                          <input ref="fileInputComments" @change="uploadAttachment" type="file" accept="image/*,video/*"
                            multiple class="form-control" />
                        </div>

                        <div v-if="filesBase64.length > 0" class="mt-3">
                          <h5>File Previews:</h5>
                          <div v-for="(file, index) in filesBase64" :key="index" class="mb-2">
                            <a href="#" style="color: #0468B1;" @click.prevent="openFilesComment(file)">{{ file.name
                              }}</a>
                          </div>
                        </div>

                        <div class="row mt-5">
                          <div class="col-md-8">
                            <button @click="cancelAppealComplaint" type="button" class="btn btn-lg btn-block w-100"
                              style="background-color: #0468B1; color: white">
                              Submit
                            </button>
                          </div>
                          <div class="col-md-3 mt-3 mt-md-0">
                            <button type="button" @click="cancelAppealModal"
                              class="btn btn-outline-primary btn-lg btn-block rounded">
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="reOpenAppeal" class="modal-mask" @click="cancelReOpenModal">
          <div class="modal-wrapper" @click.stop>
            <div class="modal-container scrollable">
              <div class="modal-header mt-3" style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                ">
                Re open Complaint
              </div>
              <div class="modal-body">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-8 offset-md-2">
                      <form>


                        <label class="col-form-label" style="
                            font-family: sans-serif;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 24px;
                          ">Reason</label>

                        <div class="d-flex align-items-center mb-3" style="
                            border-bottom: 2px solid #ff432a;
                            padding-bottom: 5px;
                          ">
                          <input class="form-control form-control-sm border-0" placeholder="Type Here"
                            v-model="assign.comment" style="
                              border: none;
                              outline: none;
                              box-shadow: none;
                            " />
                        </div>

                        <div>
                          <div>
                            <label class="mt-2">Attachments</label>
                          </div>
                          <input ref="fileInputComments" @change="uploadAttachment" type="file" accept="image/*,video/*"
                            multiple class="form-control" />
                        </div>

                        <div v-if="filesBase64.length > 0" class="mt-3">
                          <h5>File Previews:</h5>
                          <div v-for="(file, index) in filesBase64" :key="index" class="mb-2">
                            <a href="#" style="color: #0468B1;" @click.prevent="openFilesComment(file)">{{ file.name
                              }}</a>
                          </div>
                        </div>

                        <div class="row mt-5">
                          <div class="col-md-8">
                            <button @click="reOpenAppealComplaint" type="button" class="btn btn-lg btn-block w-100"
                              style="background-color: #0468B1; color: white">
                              Submit
                            </button>
                          </div>
                          <div class="col-md-3 mt-3 mt-md-0">
                            <button type="button" @click="cancelReOpenModal"
                              class="btn btn-outline-primary btn-lg btn-block rounded">
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="revertModal" class="modal-mask" @click="closeRevertModal">
          <div class="modal-wrapper" @click.stop>
            <div class="modal-container scrollable">
              <div class="modal-header mt-3" style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                ">
                Revert Complaint
              </div>
              <div class="modal-body">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-8 offset-md-2">
                      <form @submit.prevent="revertComplaint">
                        <label class="col-form-label" style="
                            font-family: sans-serif;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 24px;
                          ">Reason</label>

                        <div class="d-flex align-items-center mb-3" style="
                            border-bottom: 2px solid #ff432a;
                            padding-bottom: 5px;
                          ">
                          <textarea class="form-control form-control-sm border-0" required placeholder="Type Here"
                            v-model="assign.comment" style="
                              border: none;
                              outline: none;
                              box-shadow: none;
                              height: 1.2rem;"></textarea>
                        </div>


                        <div>
                          <div>
                            <label class="mt-2">Attachments</label>
                          </div>
                          <input ref="fileInputComments" @change="uploadAttachment" type="file" accept="image/*,video/*"
                            multiple class="form-control" />
                        </div>

                        <div v-if="filesBase64.length > 0" class="mt-3">
                          <h5>File Previews:</h5>
                          <div v-for="(file, index) in filesBase64" :key="index" class="mb-2">
                            <a href="#" style="color: #0468B1;" @click.prevent="openFilesComment(file)">{{ file.name
                              }}</a>
                          </div>
                        </div>


                        <div class="row mt-5">
                          <div class="col-md-8">
                            <button type="submit" class="btn btn-lg btn-block w-100 rounded"
                              style="background-color: #0468B1; color: white">
                              Revert
                            </button>
                          </div>
                          <div class="col-md-3 mt-3 mt-md-0">
                            <button type="button" @click="closeRevertModal"
                              class="btn btn-outline-primary btn-lg btn-block rounded">
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="updateModal" class="modal-mask" @click="closeUpdateModal">
          <div class="modal-wrapper" @click.stop>
            <div class="modal-container scrollable">
              <div class="modal-header mt-3" style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                ">
                Update Status
              </div>
              <div class="modal-body">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-8 offset-md-2">
                      <form @submit.prevent="CloseStatusComplaint">
                        <!-- <label class="col-form-label" style="
                            font-family: sans-serif;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 24px;
                          ">Title</label>
                        <div class="d-flex align-items-center mb-3" style="
                            border-bottom: 2px solid #ff432a;
                            padding-bottom: 5px;
                          ">
                          <input class="form-control form-control-sm border-0" v-model="closeStatus.title"
                            placeholder="Type Here" style="
                              border: none;
                              outline: none;
                              box-shadow: none;
                            " required />
                        </div> -->
                        <label class="col-form-label" style="
                            font-family: sans-serif;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 24px;
                          ">Feedback</label>
                        <div class="d-flex align-items-center mb-3" style="
                            border-bottom: 2px solid #ff432a;
                            padding-bottom: 5px;
                          ">
                          <textarea class="form-control form-control-sm border-0" rows="3" required
                            v-model="closeStatus.feedback" placeholder="Type Here" style="
                              border: none;
                              outline: none;
                              box-shadow: none;
                            "></textarea>
                        </div>
                        <div>
                          <div>
                            <label class="mt-2">Attachments</label>
                          </div>
                          <input ref="fileInputComments" @change="uploadAttachment" type="file" accept="image/*,video/*"
                            multiple class="form-control" />
                        </div>
                        <div v-if="filesBase64.length > 0" class="mt-3">
                          <h5>File Previews:</h5>
                          <div v-for="(file, index) in filesBase64" :key="index" class="mb-2">
                            <a href="#" style="color: #0468B1;" @click.prevent="openFilesComment(file)">{{ file.name
                              }}</a>
                          </div>
                        </div>

                        <label class="col-form-label mt-2" style="
                            font-family: sans-serif;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 24px;
                          ">Status</label>
                        <div class="select-container">
                          <select class="form-control form-control-sm border-0 select-field"
                            v-model="closeStatus.status" required>
                            <option value="" disabled selected>
                              Select...
                            </option>

                            <option v-if="role == 'DC' && complaint.stage != 'Resolution'" value="Close">Close</option>
                            <option value="Cancel"
                              v-if="complaint.stage == 'Registration' && complaint.status != 'Cancel'">
                              Cancel</option>
                            <option v-if="role == 'DC' && complaint.status == 'Complete'" value="assignEO">Assign EO
                            </option>

                          </select>
                          <i class="fa fa-caret-down select-icon"></i>
                        </div>
                        <template v-if="closeStatus.status == 'assignEO' && complaint.status == 'Complete'">
                          <div>
                            <label class="col-form-label" style="
                            font-family: sans-serif;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 24px;
                          ">Select EO</label>
                            <div class="select-container">
                              <select class="form-control form-control-sm border-0 select-field"
                                v-model="closeStatus.eoId" required>
                                <option value="" disabled selected>
                                  Select...
                                </option>

                                <option :value="eo.admin.id" v-for="eo in dcEOs">{{ eo.admin.email }}</option>

                              </select>
                              <i class="fa fa-caret-down select-icon"></i>
                            </div>


                            <label class="col-form-label" style="
                            font-family: sans-serif;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 24px;
                          ">Days</label>

                            <div class="d-flex align-items-center mb-3" style="
                            border-bottom: 2px solid #ff432a;
                            padding-bottom: 5px;
                          ">
                              <input class="form-control form-control-sm border-0" required v-model="closeStatus.eoDays"
                                placeholder="Type Here" style="
                              border: none;
                              outline: none;
                              box-shadow: none;
                            " />
                            </div>
                          </div>
                        </template>




                        <!-- <label class="col-form-label" style="
                            font-family: sans-serif;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 24px;
                          ">Select Date</label>

                        <div class="d-flex align-items-center mb-3" style="
                            border-bottom: 2px solid #ff432a;
                            padding-bottom: 5px;
                          ">
                          <input type="datetime-local" v-model="closeStatus.date"
                            class="form-control form-control-sm border-0" placeholder="Type Here" required style="
                              border: none;
                              outline: none;
                              box-shadow: none;
                            " />
                        </div> -->

                        <div class="row mt-5">
                          <div class="col-md-8">
                            <button type="submit" class="btn btn-lg btn-block w-100"
                              style="background-color: #0468B1; color: white">
                              Update
                            </button>
                          </div>
                          <div class="col-md-3 mt-3 mt-md-0">
                            <button type="button" @click="closeUpdateModal"
                              class="btn btn-outline-primary btn-lg btn-block rounded">
                              Cancel
                            </button>

                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showCommentModel" class="modal-mask" @click="closeCommentModal">
      <div class="modal-wrapper" @click.stop>
        <div class="modal-container scrollable">
          <div class="modal-header mt-3" style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                ">
            <h3>Add Comment</h3>



          </div>
          <div class="modal-body">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-8 offset-md-2">
                  <form @submit.prevent="addCommentTracking">
                    <label class="col-form-label" style="
                            font-family: sans-serif;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 24px;
                          ">Comment</label>

                    <div class="d-flex align-items-center mb-3" style="
                            border-bottom: 2px solid #ff432a;
                            padding-bottom: 5px;
                          ">
                      <textarea class="form-control form-control-sm border-0" rows="2" placeholder="Type Here"
                        v-model="commentTracking" style="
                              border: none;
                              outline: none;
                              box-shadow: none;
                            " required></textarea>
                    </div>

                    <div>
                      <div>
                        <label class="mt-2">Attachments</label>
                      </div>
                      <input ref="fileInputComments" @change="uploadAttachment" type="file" accept="image/*,video/*"
                        multiple class="form-control" />
                    </div>

                    <div v-if="filesBase64.length > 0" class="mt-3">
                      <h5>File Previews:</h5>
                      <div v-for="(file, index) in filesBase64" :key="index" class="mb-2">
                        <a href="#" style="color: #0468B1;" @click.prevent="openFilesComment(file)">{{ file.name }}</a>
                      </div>
                    </div>



                    <div class="row mt-5">
                      <div class="col-md-8">
                        <button type="submit" class="btn btn-lg btn-block w-100"
                          style="background-color: #0468B1; color: white">
                          <span>Save</span>

                        </button>
                      </div>
                      <div class="col-md-3 mt-3 mt-md-0">
                        <button type="button" @click="closeCommentModal"
                          class="btn btn-outline-primary btn-lg btn-block rounded">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toRaw } from "vue";
import isNumber from "@/helpers/helpers";

export default {
  mixins: [isNumber],
  data() {
    return {
      role: localStorage.getItem('role'),
      roles: ['DC', 'inquiry-officer', 'Department-Head', 'Department-FocalPerson', 'Department-Unit'],
      complaint: {
        user_profile: {
          name: "",
          father_name: "",
          cnic: "",
          profession: "",
          address: "",
          phone: "",
        },
        referenceNo: null,
        status: ""
      },
      users: [],
      status: [],
      districtOfficers: [],
      errors: [],
      admins: [],
      tracking: [],
      assign: {
        admin_id: "",
        comment: "",
        complaint_id: "",
        remark: "",
        isPriority: false,
      },
      filterData: {
        countryId: "",
        provinceId: "",
        districtId: "",
        otherRegistrar: false,
      },
      dcassignrole: {
        role: "",
        departments: [],
        departmentId: ""
      },
      dhassignrole: {
        role: "unit"
      },
      closeStatus: {
        title: "",
        feedback: "",
        status: "",
        date: "",
        eoId: "",
        eoDays: "",
      },
      countries: [],
      provinces: [],
      districts: [],
      dcEOs: [],
      documentUrl: "",
      complainId: null,
      commentTracking: "",
      loading: false,
      activeTab: "complaint-detail",
      showModal: false,
      updateModal: false,
      completeStatus: false,
      showCommentModel: false,
      cancelAppeal: false,
      reOpenAppeal: false,
      revertModal: false,
      filesBase64: []
    };
  },
  mounted() {
    this.complainId = this.$route.params.id;
    this.fetch(this.complainId);
    this.fetchAdmins(this.complainId);
    this.complaintTracking(this.complainId);
    this.districtEO();
    this.allDistricts();
    this.assign.complaint_id = this.complainId;
    const query = this.$route.query;
    if (query.query === 'comment') {
      const trackingTabButton = document.getElementById('profile-tab');
      if (trackingTabButton) {
        trackingTabButton.click();
      }
    }

  },
  methods: {
    displayValue(value) {
      return value || "N/A";
    },
    fetchCountry() {
      this.loading = true;
      this.$store
        .dispatch("country/getAll")
        .then((response) => {
          this.countries = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    allDistricts() {
      this.loading = true;
      this.$store
        .dispatch("districts/getAll")
        .then((response) => {
          this.districts = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    getallDistricts() {
      this.loading = true;
      this.$store
        .dispatch("districts/getAllDistricts")
        .then((response) => {
          this.districts = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    provinceChanged() {
      const id = this.filterData.provinceId;
      this.loading = true;
      this.$store
        .dispatch("districts/getAllDistrictsByProvince", { id })
        .then((response) => {
          this.districts = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
    countryChanged() {
      const id = this.filterData.countryId;
      this.loading = true;
      this.$store
        .dispatch("province/getAllProvincesByCountry", { id })
        .then((response) => {
          this.provinces = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
    getDc() {
      const id = this.filterData.districtId;
      this.loading = true;
      this.$store
        .dispatch("districts/getDistrictDC", { id })
        .then((response) => {
          this.admins = response.data;
          this.assign.admin_id = response.data[0].admin_id;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    getRegistrar() {
      const id = this.filterData.districtId;
      this.loading = true;
      this.$store
        .dispatch("districts/getDistrictRegistar", { id })
        .then((response) => {
          this.admins = response.data;
          this.assign.admin_id = response.data[0].admin_id;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    getDepartmentHead() {
      const id = this.dcassignrole.departmentId;
      this.loading = true;
      this.$store
        .dispatch("complaint/getDepartmentHead", { id })
        .then((response) => {
          this.admins = response.data;
          this.assign.admin_id = response.data[0].admin_id;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show("No department head assigned to the selected department", {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
    getDepartmentUnit(event) {
      const unitName = event.target.value;
      const selectedAdmin = this.admins.find(admin => admin.unit_name === unitName);
      this.assign.admin_id = selectedAdmin.admin_id;
    },
    assignAdmin() {
      this.loading = true;
      this.errors = [];
      const data = this.assign;
      data.files = this.filesBase64;
      data.complaint_id = this.complainId;
      this.$store
        .dispatch("complaint/storeComplaintStatus", { data })
        .then(() => {
          this.$toast.show("Successfuly added.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.errors = [];
          this.filesBase64 = [];
          Object.keys(this.assign).forEach((key) => (this.assign[key] = null));
          this.assign.admin_id = "";
          if (this.role === 'Department-Unit') {
            this.complaint.status = 'Submit';
          } else {
            this.complaint.status = 'Assigned';
          }
          this.showModal = false;
          this.loading = false;
          this.complaintTracking(this.complainId);
          this.fetch(this.complainId);
        })
        .catch((e) => {
          this.loading = false;
          this.errors = e.error;
        });
    },
    CompleteStatus() {
      this.loading = true;
      this.errors = [];
      const data = {
        'complaint_id': this.complainId,
        'comment': this.assign.comment,
        'files': this.filesBase64
      };
      this.$store
        .dispatch("complaint/storeCompleteStatus", { data })
        .then(() => {
          this.loading = false;
          this.$toast.show("Successfuly added.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.errors = [];
          this.filesBase64 = [];
          Object.keys(this.assign).forEach((key) => (this.assign[key] = null));
          this.assign.admin_id = "";
          this.complaint.status = 'Complete';
          this.completeStatus = false;
          this.complaintTracking(this.complainId);
        })
        .catch((e) => {
          this.loading = false;
          this.errors = e.error;
        });
    },
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("complaint/getSingle", { id })
        .then((response) => {
          this.loading = false;
          this.complaint = response.data;
          this.documentUrl = this.complaint.media.image_url;
          this.complaint.status = response.data.status;
          if (this.complaint.admin_id === null) {
            this.complaint.admin_id = "";
          }
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    fetchAdmins(id) {
      this.loading = true;
      this.$store
        .dispatch("complaint/getAdmins", { id })
        .then((response) => {
          if (this.role == 'inquiry-officer') {
            this.dcassignrole.departments = response.data;
          }
          else {
            this.admins = response.data;
            if (this.role == 'Registrar') {
              if (this.admins.length === 1) {
                this.assign.admin_id = this.admins[0].admin_id;
                this.filterData.districtId = this.admins[0].district.id;
              }

            }
          }
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    otherRegistrar(event) {
      if (event.target.checked) {
        this.filterData.otherRegistrar = true;
        this.getallDistricts();
        Object.keys(this.assign).forEach((key) => (this.assign[key] = null));
        this.admins = [];
        this.filterData.districtId = "";
      } else {
        this.filterData.otherRegistrar = false;
        this.fetchAdmins(this.complainId);
      }
    },
    complaintTracking(id) {
      this.loading = true;
      this.$store
        .dispatch("complaint/complaintTracking", { id })
        .then((response) => {
          this.tracking = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    radioChanged(event) {
      this.loading = true;
      this.errors = [];
      const data = { role: event.target.value };
      if (data.role == 'IO') {
        this.dcassignrole.role = "";
        this.assign.admin_id = "";
        this.dcassignrole.departmentId = "";
        this.fetchAdmins(this.complainId);
      } else {
        this.admins = [];
        this.dcassignrole.role = 'DH';
        this.loading = true;
        this.$store
          .dispatch("complaint/getDepartments")
          .then((response) => {
            this.dcassignrole.departments = response.data;
            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
            console.log(e.error);
          });
      }
    },
    dhSelectDesignation(event) {
      const data = { role: event.target.value };
      if (data.role == 'unit') {
        var selectElement = document.getElementById('departmentUnitSelect');
        selectElement.selectedIndex = 0;
        this.assign.admin_id = "";
        this.fetchAdmins(this.complainId);
      } else {
        this.assign.admin_id = "";
        this.admins = [];
        this.loading = true;
        this.$store
          .dispatch("complaint/getDepartmentFocalPersons")
          .then((response) => {
            this.admins = response.data;
            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
            console.log(e.error);
          });
      }
    },
    CloseStatusComplaint() {
      const data = toRaw(this.closeStatus);
      data.complaint_id = this.complaint.id;
      data.files = this.filesBase64;
      this.loading = true;
      this.errors = [];
      this.$store
        .dispatch("complaint/UpdateComplaintStatus", { data })
        .then(() => {

          this.$toast.show("Successfuly added.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.errors = [];
          this.updateModal = false;
          if (this.closeStatus.status.trim() === "assignEO") {
            this.$router.push("/complaints");
          }
          this.filesBase64 = [];
          Object.keys(this.assign).forEach((key) => (this.assign[key] = null));
          Object.keys(this.closeStatus).forEach((key) => (this.closeStatus[key] = ""));
          this.assign.admin_id = "";
          this.complaintTracking(this.complainId);
          this.fetch(this.complainId);
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.errors = e.error;
        });

    },
    addRef(event) {
      event.preventDefault();
      this.loading = true;
      const id = this.complaint.id;
      this.$store
        .dispatch("complaint/addRefNo", { id })
        .then((response) => {
          this.complaint.referenceNo = response.data;
          this.loading = false;
          this.$toast.show("Successfuly added.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.complaint.stage = "Investigation";
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });

    },
    addCommentTracking() {
      this.loading = true;
      const data = {
        'complaint_id': this.complainId,
        'comment': this.commentTracking,
        'media': this.filesBase64
      };
      this.$store
        .dispatch("complaint/storeCommentTracking", { data })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            this.$toast.show("Successfuly added", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            this.commentTracking = "";
            this.showCommentModel = false;
            this.filesBase64 = [];
            this.complaintTracking(this.complainId);
          } else {
            this.$toast.show("Failed to save", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
    closeModal() {
      this.showModal = false;
    },
    closeCompleteModal() {
      this.completeStatus = false;
    },
    closeUpdateModal() {
      this.updateModal = false;
    },
    closeCommentModal() {
      this.showCommentModel = false;
    },
    closeRevertModal() {
      this.revertModal = false;
    },
    cancelAppealModal() {
      this.cancelAppeal = false;
    },
    cancelReOpenModal() {
      this.reOpenAppeal = false;
    },
    revertComplaint() {
      this.loading = true;
      const id = this.complainId;
      const data = { ...this.assign };
      data.files = this.filesBase64;
      this.$store
        .dispatch("complaint/revertComplaint", { id, data })
        .then((response) => {
          this.loading = false;
          this.base64Data = [];
          if (response.success === true) {
            Object.keys(this.assign).forEach((key) => (this.assign[key] = null));
            this.$router.push('/complaints');
            this.revertModal = false;
            this.$toast.show("Successfuly updated", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
          }
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    districtEO() {
      this.$store
        .dispatch("complaint/districtEo")
        .then((response) => {
          this.dcEOs = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    openPdf() {
      window.open(this.documentUrl, '_blank');
    },
    openFilesComment(file) {
      const win = window.open();
      if (file.type === 'Image') {
        win.document.write('<img src="' + file.url + '" alt="' + file.name + '">');
      } else if (file.type === 'Video') {
        win.document.write('<video width="500" height="400" controls><source src="' + file.url + '" type="video/mp4"></video>');
      } else if (file.type === 'Pdf') {
        window.open(file.url, '_blank');
      }
    },
    openFiles(event, item) {
      event.preventDefault();
      let mediaArray = Array.isArray(item.media) ? item.media : [item.media];
      mediaArray.forEach((doc, index) => {

        window.open(doc.image_url, '_blank', `window${index}`);
      });
    },
    uploadAttachment(event) {
      const files = event.target.files;
      this.filesBase64 = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onload = (e) => {
          let base64String = e.target.result;
          const mimeType = file.type;
          let type;
          if (mimeType.includes('image')) {
            type = 'Image';
          } else if (mimeType.includes('video')) {
            type = 'Video';
          } else if (mimeType.includes('pdf')) {
            type = 'Pdf';
          } else {
            type = 'Unknown';
          }
          const base64Data = `data:${mimeType};base64,${base64String.split(',')[1]}`;
          this.filesBase64.push({ url: base64Data, name: file.name, type: type });
        };
        reader.readAsDataURL(file);
      }
    },
    handlePDF(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64String = btoa(
          new Uint8Array(reader.result)
            .reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        this.mediaType = "application/pdf";
        this.complaint.document = base64String;
        const blob = new Blob([reader.result], { type: 'application/pdf' });
        this.fileUrl = URL.createObjectURL(blob);
      };

      reader.readAsArrayBuffer(file);
    },
    openTrackingFile(files) {

      files.forEach((item) => {
        const newWindow = window.open(item.media.image_url, "_blank");
      });
    },
    reOpenAppealComplaint() {
      this.loading = true;
      const data = { ...this.assign };
      data.complaint_id = this.complainId;;
      data.files = this.filesBase64;
      this.$store
        .dispatch("complaint/reopoeAppealComplaint", { data })
        .then((response) => {
          this.loading = false;
          this.base64Data = [];
          if (response.success === true) {
            Object.keys(this.assign).forEach((key) => (this.assign[key] = null));
            this.reOpenAppeal = false;
            location.reload(true);
            this.$toast.show("Successfuly updated", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
          }
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    cancelAppealComplaint() {
      this.loading = true;
      const data = { ...this.assign };
      data.complaint_id = this.complainId;;
      data.files = this.filesBase64;
      this.$store
        .dispatch("complaint/cancelAppealComplaint", { data })
        .then((response) => {
          this.loading = false;
          this.base64Data = [];
          if (response.success === true) {
            Object.keys(this.assign).forEach((key) => (this.assign[key] = null));
            this.cancelAppeal = false;
            this.$toast.show("Successfuly updated", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            this.fetch(this.complainId);
          }
        })
        .catch((e) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.nav-link.active {
  padding: 15px 80px !important;
  border: 0 !important;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.nav-link {
  background-color: #ddd;
  margin-right: 10px;
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  padding: 15px 80px !important;
}

.tab-pane {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.comment-widgets .comment-row:hover {
  background: rgba(0, 0, 0, 0.02);
  cursor: pointer;
}

.comment-widgets .comment-row {
  padding: 15px;
}

.comment-text:hover {
  visibility: hidden;
}

.comment-text:hover {
  visibility: visible;
}

.label {
  padding: 3px 10px;
  line-height: 13px;
  color: #ffffff;
  font-weight: 400;
  border-radius: 4px;
  font-size: 75%;
}

.round img {
  border-radius: 100%;
}

.label-info {
  background-color: #1976d2;
}

.label-success {
  background-color: green;
}

.label-danger {
  background-color: #ef5350;
}

.action-icons a {
  padding-left: 7px;
  vertical-align: middle;
  color: #99abb4;
}

.action-icons a:hover {
  color: #1976d2;
}

.mt-100 {
  margin-top: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-wrapper {
  width: 40%;
}

.modal-container {
  max-height: 80%;
  overflow-y: auto;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.modal-header,
.modal-footer {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.modal-body {
  padding: 20px;
}

.modal-default-button {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.select-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 2px solid #0468B1;
  padding-bottom: 5px;
}

.select-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 2px solid #0468B1;
  padding-bottom: 5px;
}


.d-flex.align-items-center.mb-3 {
  border-bottom: 2px solid #0468B1 !important;
}

.select-field {
  flex: 1;
  border: none;
  outline: none;
  box-shadow: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  background: transparent;
}

.select-icon {
  color: #0468B1;
  cursor: pointer;
}

.form-control:disabled {
  background-color: #ffffff !important;
}

.rounded {
  border-radius: 20px !important;
}

.btn-outline-primary:hover {
  background-color: #0468B1 !important;
  color: white !important;
}

.scrollable {
  overflow-y: auto;
  max-height: 95vh;
}

.scrollable::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: #0468B1;
  border-radius: 10px;
}

.scrollable::-webkit-scrollbar-thumb:hover {
  background-color: #034074;
}
</style>
